import api from './api'

export default {
  getUsers() {

    return api.get('/api/v1/users')
  },
  getUser(uuid) {
    return api.get(`/api/v1/user/${uuid}`)
  },
  getUserByEmail(params) {
      return api.post(`/api/v1/user/${params.email}`, {exclude: params.exclude})
  },
  createUser(params) {
    console.debug(params)
    return api.post('/api/v1/user', params)
  },
  updateUser(params) {
    console.debug(params)
    return api.put(`/api/v1/user/${params.uuid}`, params)
  },
  getUserEditorCompanies() {
    return api.get(`/api/v1/user/editor/companies`)
  },
  getUserEditorCompanyProducts(companyUuid) {
    return api.get(`/api/v1/user/editor/company/${companyUuid}/products`)
  },
  getUserEditorCompanyProductDepartments(companyUuid, productUuid) {
    return api.get(`/api/v1/user/editor/company/${companyUuid}/product/${productUuid}/departments`)
  },
  deleteUser(uuid) {
    console.debug(uuid)
    return api.delete(`/api/v1/user/${uuid}`)
  }

}
