import userService from "../../services/user";

const state = ({
    total: 0,
    page: 1,
    users: [],
    user: [],
  companies: []
});

const mutations = ({
    SET_USERS(state, users) {
        state.users = users
    },
    SET_USER(state, user) {
        state.user = user
    },
    SET_TOTAL(state, total) {
        state.total = total
    },
    SET_PAGE(state, page) {
        state.page = page
    },
  SET_COMPANIES(state, companies) {
    state.companies = companies
  },
  SET_PRODUCTS(state, products) {
    state.products = products
  },
  SET_COMPANY_PRODUCTS_DEPARTMENTS(state, companies) {
    state.companiesProductsDepartments = companies
  },
});

const actions = ({
    async getUsers({commit}) {
      commit('SET_USER', 1)

      return userService.getUsers()
    },
    async getUser({commit}, uuid) {
        commit('SET_USER', 1)

        return  userService.getUser(uuid)

    },
  async getUserByEmail({commit}, params) {
      commit('SET_USER', 1)

      return userService.getUserByEmail(params)
  },
    async createUser({commit}, params) {
      commit('', '')
      return userService.createUser(params)
    },
    async updateUser({commit}, params) {
        userService.updateUser(params)
            .then((response) => {
                console.debug(response.data)
                commit('SET_PAGE', 1)
            })
    },
  async getUserEditorCompanies({commit}, params) {
    userService.getUserEditorCompanies(params)
      .then((response) => {
        let companies = []
        companies.push({value: '', text: 'Select Company'})
        response.data.map(function (value) {
          companies.push({value: value.uuid, text: value.name})
        });
        commit('SET_COMPANIES', companies)
      })
  },
  async getUserEditorCompanyProducts({commit}, companyId) {
    commit('SET_PRODUCTS', [])

    return userService.getUserEditorCompanyProducts(companyId)


    /*companyService.getProducts(companyId)
        .then((response) => {
            let products = []
            response.data.map(function (value) {
                products.push({value: value.uuid, text: value.name})
            });
            commit('SET_PRODUCTS', products)
        })*/
  },
  async getUserEditorCompanyProductDepartments({commit}, params) {
    commit('SET_PRODUCTS', [])

    return userService.getUserEditorCompanyProductDepartments(params.companyUuid, params.productUuid)


    /*companyService.getProducts(companyId)
        .then((response) => {
            let products = []
            response.data.map(function (value) {
                products.push({value: value.uuid, text: value.name})
            });
            commit('SET_PRODUCTS', products)
        })*/
  },
    async deleteUser({commit}, params) {
        userService.deleteUser(params)
            .then((response) => {
                console.debug(response)
                commit('SET_PAGE', 1)
            })
    }
});

const getters = ({
  getUsersState(state) {
        return state.users
    },
  getUserState(state) {
        return state.user
    },
    getTotalUsers(state) {
        return state.total
    },
    getUsersPage(state) {
        return state.page
    }
})

export default {
    state,
    mutations,
    actions,
    getters
}


