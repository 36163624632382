import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: () => import('../views/auth/login.vue'),
  },

    {
        path: '/forgot-password',
        name: 'Forgot Password',
        component: () => import('../views/forgot-password/view.vue'),
    },
    {
        path: '/new-password/:code',
        name: 'New Password',
        component: () => import('../views/forgot-password/new.vue'),
    },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('../views/dashboard.vue'),
    meta: {
      requiresAuth: true
    }
    /* meta: {
                requiresAuth: true,
                is_admin : true
            }
     */
  },
  {
    path: '/recordings',
    name: 'Recordings',
    component: () => import('../views/video/list.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/upload-status',
    name: 'Upload Status',
    component: () => import('../views/video/upload_status.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/users',
    name: 'Users',
    component: () => import('../views/user/list.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/create',
    name: 'create-user',
    component: () => import('../views/user/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/update/:uuid',
    name: 'update-user',
    component: () => import('../views/user/create.vue'),
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if (store.getters['isLoggedIn']) {
      next()
      return
    }
    next('/')
  } else {
    next()
  }
})

export default router
