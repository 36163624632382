import api from './api'

export default {
    forgotPassword (email) {
        return api.post('/api/v1/forgot-password', email)
    },
    newPassword (data) {
        return api.post('/api/v1/new-password', data)
    },
    verifyCode (code) {
        return api.post('/api/v1/forgot-password/verify-code', code)
    }
}
