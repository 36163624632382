<template>
  <div class="menu" v-if="user">
    <b-navbar toggleable="lg" type="primary" class="flex-column p-0">
      <div class="bg-black w-100 d-flex p-3">
        <b-navbar-brand href="/recordings"><img alt="logo" src="../assets/launchyou-logo-flat-white.svg"></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <span class="text-white">{{ user.first_name }}</span>
            </template>
            <b-dropdown-item :to="'/user/update/' + user.uuid">Profile</b-dropdown-item>
            <b-dropdown-item @click="logoutUser()">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>
        </b-navbar-nav>
      </div>
      <b-collapse id="nav-collapse" is-nav class="pt-4 px-3 bg-gray-1 w-100 main-nav">
        <b-navbar-nav>
          <b-nav-item to="/recordings" exact exact-active-class="active">Recordings</b-nav-item>
          <b-nav-item to="/upload-status" exact exact-active-class="active">Copy Status</b-nav-item>
          <b-nav-item to="/users"  v-if="user.is_admin == '1'" exact exact-active-class="active">User Management</b-nav-item>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
      </b-collapse>
    </b-navbar>
</div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  data() {
    return {
      user: JSON.parse(localStorage.getItem('user'))
    }
  },
  methods: {
    ...mapActions(['logout']),
    logoutUser: function () {
      this.logout()
   //   this.$store.dispatch('logout')
          .then(() => {
            window.location.href = '/'
           // this.$router.push('/login')
          })
    }
  },
}
</script>

<style>
html {
	font-size: 14px;
}
table tbody td{
  color:#6c757d;
}
table td {
  vertical-align: middle!important;
}
.main-container{
  background-image: url(../assets/home-header-bg.svg);
  background-repeat:repeat-x;
  background-size:300px;
}
.bg-black{
  background-color:#000000;
}
.bg-gray-1{
  background-color:#f7f7ff
}
.main-nav{
  border-bottom:1px solid #e8e8e8;
}
.main-nav a{
  color:#000000!important;
  border-bottom:5px solid transparent;
}
#nav-collapse.main-nav a.active{
  border-color:#007ec1;
  font-weight: normal;
}
.b-table-details{
  border-top:1px solid #e8e8e8;
}
.b-table-has-details td, .b-table-details td{
  background:#f7f7ff;
}
.b-table-details > td{
  padding:20px!important
}
</style>
