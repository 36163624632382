import api from './api'

export default {
    login (credentials) {
        return api.post('/api/v1/auth/login', credentials)
    },
    logout () {
        return api.post('/api/v1/auth/logout', {},  { headers: {
            'Accept': 'application/json'}}
        )
    }
}
