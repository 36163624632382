import api from './api'

export default {
    getVideos (params) {

        let queryString = {};

        if("page" in params && params.page !== '') {
            queryString.page = params.page;
        }

        if("perPage" in params && params.perPage !== '') {
            queryString.perPage = params.perPage;
        }

        if("keyword" in params && params.keyword !== '') {
            queryString.keyword = params.keyword;
        }

        if("sortBy" in params && params.sortBy !== '') {
            queryString.sortBy = params.sortBy;
        }

        if("sortDirection" in params && params.sortDirection !== '') {
            queryString.sortDirection = params.sortDirection;
        }

        if("user" in params && params.user !== '') {
            queryString.user = params.user;
        }


        if("from" in params && params.from !== '') {
            queryString.from = params.from;
        }

        if("to" in params && params.to !== '') {
            queryString.to = params.to;
        }

        let queryString2 = '';
        Object.keys(queryString).forEach(key => {
            queryString2 += key + '=' +  queryString[key] + '&';
        });

        return api.get('/api/v1/videos?' + queryString2)
    },
    getUploadStatus (params) {

        let queryString = {};

        if("page" in params && params.page !== '') {
            queryString.page = params.page;
        }

        if("perPage" in params && params.perPage !== '') {
            queryString.perPage = params.perPage;
        }

        if("keyword" in params && params.keyword !== '') {
            queryString.keyword = params.keyword;
        }

        if("sortBy" in params && params.sortBy !== '') {
            queryString.sortBy = params.sortBy;
        }

        if("sortDirection" in params && params.sortDirection !== '') {
            queryString.sortDirection = params.sortDirection;
        }

        if("type" in params && params.type !== '') {
            queryString.type = params.type;
        }


        if("from" in params && params.from !== '') {
            queryString.from = params.from;
        }

        if("to" in params && params.to !== '') {
            queryString.to = params.to;
        }

        let queryString2 = '';
        Object.keys(queryString).forEach(key => {
            queryString2 += key + '=' +  queryString[key] + '&';
        });
        return api.get('/api/v1/upload-status?' + queryString2)
    },
    createVideo(params) {
        return api.post('/api/v1/video', params)
    },
    getVideo(uuid) {
        return api.get(`/api/v1/video/${uuid}`)
    },
    getRecordingUsers() {
        return api.get(`/api/v1/recording-users`)
    },
    copyRecording(uuid) {
        return api.post(`/api/v1/recording/${uuid}/copy`)
    },
    shareUrl(uuid) {
        return api.post(`/api/v1/recording/${uuid}/share`)
    },
    copyAllRecording(uuid) {
        return api.post(`/api/v1/video/${uuid}/copy`)
    },
    deleteVideo(uuid) {
        return api.delete(`/api/v1/video/${uuid}`)
    },
    refreshVideo(uuid) {
        return api.post(`/api/v1/video/${uuid}/refresh`)
    },
    getEvents(uuid) {
        return api.get(`/api/v1/recording/${uuid}/events`)
    },
    ignore(uuid) {
        return api.put(`/api/v1/recording/${uuid}/ignore`)
    },
    refreshVideos() {
        return api.post(`/api/v1/videos/refresh`)
    },

}
