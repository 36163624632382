const state = ({
    isLoading: false
});

const mutations = ({
    loading(state, isLoading) {
        if (isLoading) {
            state.isLoading = true;
        } else {
            state.isLoading = false;
        }
    }
});

export default {
    state,
    mutations
}
