import axios from 'axios'
import {config} from "../../config";

const api = axios.create({
    baseURL: config.apiBaseUrl,
    withCredentials: false,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
})

const token = localStorage.getItem('auth_token')
if (token) {
    api.defaults.headers.common['Bearer'] = token
}

export default api
