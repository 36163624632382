import forgotPasswordService from '../../services/forgotPassword'

const state = ({
    status: ''
});

const mutations = ({
    auth_request(state) {
        state.status = 'loading'
    },
    auth_success(state, token, user) {
        state.status = 'success'
        state.token = token
        state.user = user
    },
    auth_error(state) {
        state.status = 'error'
    }
});

const actions = ({
    forgotPassword({commit}, email) {
        console.log(email)
        commit('SET_COMMENT', '')
        return forgotPasswordService.forgotPassword(email)
    },
    newPassword({commit}, data) {
        commit('SET_COMMENT', '')
        return forgotPasswordService.newPassword(data)
    },
    verifyCode({commit}, code) {
        commit('SET_COMMENT', '')
        return forgotPasswordService.verifyCode(code)
    },
});

const getters = ({
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
});

export default {
    state,
    mutations,
    actions,
    getters
}
