import videoService from "../../services/video";

const state = ({
    total: 0,
    page: 1,
    video: [],
    videos: []
});

const mutations = ({
    SET_VIDEOS(state, videos) {
        state.videos = videos
    },
    SET_VIDEO(state, video) {
        state.video = video
    },
    SET_TOTAL(state, total) {
        state.total = total
    },
    SET_PAGE(state, page) {
        state.page = page
    }
});

const actions = ({
    async getVideos({commit}, params) {
      commit('SET_VIDEO', 1)

      return  videoService.getVideos(params)
    },
    async getRecordingUsers({commit}) {
        commit('SET_VIDEO', 1)

        return  videoService.getRecordingUsers()
    },
    async getVideo({commit}, params) {
        videoService.getVideo(params)
            .then((response) => {
                commit('SET_VIDEO', response.data)
            })
    },
    async createVideo({commit}, params) {
        videoService.createVideo(params)
            .then((response) => {
                console.debug(response.data)
                commit('SET_PAGE', 1)
            })
    },
    async copyRecording({commit}, params) {
        commit('SET_PAGE', 1)

      return  videoService.copyRecording(params);
           // .then((response) => {
             //  return response.data
            //})
    },
    async copyAllRecording({commit}, params) {
        commit('SET_PAGE', 1)

        return videoService.copyAllRecording(params)
           /* .then((response) => {
                console.debug(response.data)
                commit('SET_PAGE', 1)
            })*/
    },
    async shareUrl({commit}, params) {
        commit('SET_PAGE', 1)

      return  videoService.shareUrl(params)

    },
    async deleteVideo({commit}, params) {
        videoService.deleteVideo(params)
            .then((response) => {
                console.debug(response)
                commit('SET_PAGE', 1)
            })
    },
    async getUploadStatus({commit}, params) {
        commit('SET_PAGE', 1)

       return videoService.getUploadStatus(params)
    },
    async refreshVideo({commit}, params) {
        commit('SET_VIDEO', 1)

        return  videoService.refreshVideo(params)
    },
    async getEvents({commit}, params) {
        commit('SET_VIDEO', 1)

        return  videoService.getEvents(params)
    },
    async ignore({commit}, params) {
        commit('SET_VIDEO', 1)

        return  videoService.ignore(params)
    },
    async refreshVideos({commit}, params) {
        commit('SET_VIDEO', 1)

        return  videoService.refreshVideos(params)
    },
});

const getters = ({
    getVideosState(state) {
        return state.videos
    },
    getVideoState(state) {
        return state.video
    },
  getVideosTotal(state) {
        return state.total
    },
  getVideosPage(state) {
        return state.page
    }
})

export default {
    state,
    mutations,
    actions,
    getters
}


