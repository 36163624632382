import authService from '../../services/auth'

const state = ({
    status: '',
    token: localStorage.getItem('auth_token') || '',
    user: {},
    isLoggedIn: false
});

const mutations = ({
    auth_request(state) {
        state.status = 'loading'
    },
    auth_success(state, token, user) {
        state.status = 'success'
        state.token = token
        state.user = user
       // state.isLoggedIn = true
    },
    auth_error(state) {
        state.status = 'error'
    },
    logout_user(state) {
        state.status = ''
        state.token = ''
        state.isLoggedIn = ''
    },
});

const actions = ({
    login({commit}, credentials) {
        return new Promise((resolve, reject) => {
            commit('auth_request')
            authService.login(credentials)
                .then(resp => {
                    console.log('resp')
                    console.log(resp.data)
                    const token = resp.data.auth_token
                    const user = resp.data.user
                    console.log(resp.data.user)
                    localStorage.setItem('auth_token', token)
                    localStorage.setItem('user', JSON.stringify(user))
                    commit('auth_success', token, user)
                    this.isLoggedIn = true
                    resolve(resp)
                })
                .catch(err => {
                    commit('auth_error')
                    localStorage.removeItem('auth_token')
                    reject(err)
                })
        })
    },
    logout({commit}) {
        return new Promise((resolve) => {
         //   commit('logout')
             authService.logout()
                 .then(() => {
                  //   this.isLoggedIn = ''
                     commit('logout_user')
                     localStorage.removeItem('auth_token')
                     localStorage.removeItem('user')
                     resolve()

                 })
                 .catch(() => {
                     commit('auth_error')
                    // reject(err)
                 })
            //localStorage.removeItem('auth_token')

            //delete this.axios.defaults.headers.common['Bearer']
            //resolve()
        })
    }
});

const getters = ({
    isLoggedIn: state => !!state,
    authStatus: state => state.status,
});

export default {
    state,
    mutations,
    actions,
    getters
}
