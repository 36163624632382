<template>
  <b-container fluid class="p-0">
    <Menu v-if="isLoggedIn"/>
    <div v-if="isLoading">
      <div>Loading...</div>
    </div>
    <router-view class="main-container"></router-view>
  </b-container>
</template>
<script>

import api from './services/api'
import {config} from '../config'
import {mapState} from 'vuex'
import Menu from "./components/Menu.vue";

export default {
  components: {Menu},
  data() {
    return {
      error: false,
      errorMessage: '',
      debug: false,
    }
  },
  computed: {
    ...mapState(['isLoading']),
      isLoggedIn : function(){ return localStorage.getItem('user') != ''}
  },
  mounted: function () {
    let self = this
    api.interceptors.request.use((config) => {
      this.$store.commit('loading', true);
      return config;
    }, (error) => {
      this.$store.commit('loading', false);
      return Promise.reject(error);
    });

    api.interceptors.response.use((response) => {
      this.$store.commit('loading', false);
      if (config.debug) {
        self.debug = true
        console.debug(response.data)
      }
      return response;
    }, (error) => {
      this.$store.commit('loading', false);
      return Promise.reject(error);
    });

    api.interceptors.response.use(undefined, function (err) {
      return new Promise(function () {

        if (err.response.status === 401) {
          self.$store.commit('logout_user');

          localStorage.removeItem('auth_token')
          localStorage.removeItem('user')
          window.location.href = '/';

        } else {
          self.error = true
          self.errorMessage = err.response.data.message
        }
        throw err;
      });
    });
  }
}
</script>

